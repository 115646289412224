import React from "react"
import { graphql } from "gatsby"

import Slab from "../components/slab"
import Container from "../components/container"
import Copy from "../components/copy"
import SEO from "../components/seo"
import MotionWrapper from "../components/motion-wrapper"

import Snow from "../images/snow.gif"

const NotFoundPage = ({ data }) => {
  const {
    entry: [entry],
  } = data.craft

  return (
    <>
      <SEO
        description={entry.metaDescription}
        title={entry.metaTitle ? entry.metaTitle : entry.title}
        image={entry.metaImage[0].url}
      />
      <MotionWrapper>
        <Slab
          first
          bg="secondary"
          color="background"
          slabSx={{ background: `url(${Snow})` }}
        >
          <Container>
            <Copy markup={entry.body} />
          </Container>
        </Slab>
      </MotionWrapper>
    </>
  )
}

export const NotFoundPageQuery = graphql`
  {
    craft {
      entry: entries(section: "error404", limit: 1) {
        title
        ... on craft_error404_error404_Entry {
          body
          metaTitle
          metaDescription
          metaImage {
            url
          }
        }
      }
    }
  }
`

export default NotFoundPage
